import React, { createContext, useState, useEffect } from 'react';

// Create the context
const AppContext = createContext();

// Create the provider component
export const AppProvider = ({ children }) => {
    
    const [crntLang,setCrntLang] = useState("");
    
    useEffect(() => {
        const CurrentLnag = localStorage.getItem("nothunLang");
        if(CurrentLnag !== null && CurrentLnag !== ""){
            setCrntLang(CurrentLnag);
        }
    },[crntLang]);

    const ChangeLange = (stateLnage) => {
        if(stateLnage === "" || stateLnage === "EN"){
            localStorage.setItem("nothunLang","AR");
            setCrntLang("AR");
        }else{
            localStorage.setItem("nothunLang","EN");
            setCrntLang("EN");
        }
      };

    return (
        <AppContext.Provider value={{ crntLang, ChangeLange }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContext;
